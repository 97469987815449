<template>
  <div>
    <div class="inner job-posting pb0">
      <div class="tit req-type">
        <h1>비밀번호 변경</h1>
        <p class="stit">
          개인정보 보호를 위해 주기적으로 비밀번호를 변경해주세요
        </p>
      </div>
      <div v-if="isAuthorized" class="sub-cont">
        <div class="myp-form-wrap">
          <dl>
            <dt>비밀번호 변경</dt>
            <dd>
              <div class="form-item-wrap">
                <div class="label">새 비밀번호</div>
                <input
                  v-model="userPw"
                  type="password"
                  class="inp"
                  ref="userPw"
                />
              </div>
              <div class="help-msg">
                영문자/숫자/특수문자를 2가지 이상 조합하여 8자리 이상
                입력해주세요.
              </div>
              <div class="form-item-wrap">
                <div class="label">새 비밀번호 확인</div>
                <input
                  v-model="userPwRepeat"
                  type="password"
                  class="inp"
                  ref="userPwRepeat"
                />
              </div>
              <div class="help-msg">동일한 비밀번호를 입력해 주세요</div>
            </dd>
          </dl>
        </div>
      </div>

      <secure-login
        ref="secLoginForm"
        :isSns="isNaver || isKakao"
        v-else
      ></secure-login>
    </div>

    <div class="myp-btn-wrap">
      <button
        v-if="isAuthorized"
        type="button"
        class="btn-save01"
        @click="savePasswd"
      >
        저장
      </button>
      <button
        v-else
        v-show="!isNaver && !isKakao"
        type="button"
        class="btn-save01"
        @click="secureLogin"
      >
        확인
      </button>
    </div>
  </div>
</template>

<script>
import SecureLogin from '../content/SecureLogin'
import { postChangePw } from 'Api/modules'
import joinMixin from 'Mixins/join'
import { mapGetters } from 'vuex'
export default {
  components: {
    SecureLogin,
  },
  mixins: [joinMixin],
  data() {
    return {
      userPw: '',
      userPwRepeat: '',
      isAuthorized: false,
      isNaver: false,
      isKakao: false,
    }
  },
  computed: {
    ...mapGetters(['account']),
  },
  methods: {
    chkPasswd() {
      if (!this.userPw) {
        this.$toasted.error('새 비밀번호를 입력하세요.')
        return false
      }
      if (!this.userPwRepeat) {
        this.$refs['userPwRepeat'].focus()
        this.$toasted.error('비밀번호 확인을 입력하세요.')
        return false
      }
      if (this.userPwRepeat.length > 0 && this.userPw != this.userPwRepeat) {
        this.$toasted.error('비밀번호와 일치하지 않습니다.')
        this.userPwRepeat = ''
        this.$refs['userPwRepeat'].focus()
        return false
      }
      if (!this.validPassword()) {
        this.$toasted.error(
          '영문자/숫자/특수문자를 2가지 이상 조합하여 8자리 이상 입력해주세요.',
        )
        this.$refs['userPw'].focus()
        this.userPw = ''
        this.userPwRepeat = ''
        return false
      }
      return true
    },
    async savePasswd() {
      if (this.chkPasswd()) {
        const res = await postChangePw({
          userId: this.account.userId,
          userPw: this.userPw,
        })
        if (res.status == 0) {
          this.$toasted.show('비밀번호가 변경되었습니다.')
          this.isAuthorized = false
        } else {
          this.$toasted.show('비밀번호가 변경중 오류가 발생했습니다.')
        }
      }
    },
    async secureLogin() {
      const res = await this.$refs.secLoginForm.login()
      if (res) {
        this.isAuthorized = true
      }
    },
  },
  created() {
    this.isKakao = this.account.joinType === 'KAKAO' ? true : false
    this.isNaver = this.account.joinType === 'NAVER' ? true : false
  },
}
</script>

<style></style>
