<template>
  <div class="sub-cont">
    <div class="myp-form-wrap">
      <dl>
        <dt>보안로그인</dt>
        <dd>
          <div class="form-item-wrap">
            <div class="label">아이디</div>
            <input
              :type="isKakaoHidden"
              class="inp"
              :value="account.userId"
              readonly
            />
            <!-- 새로고침시 초기화되는 이슈 / :value="account.userId" -> userId 로 변경 -->
          </div>
          <div class="form-item-wrap">
            <div class="label">비밀번호</div>
            <input type="password" v-model="userPw" class="inp" />
          </div>
          <div class="form-item-wrap">
            <span v-if="isSns" class="label SNS-text"
              >회원님은 {{ isKakao ? '카카오' : '네이버' }} 로그인을
              이용중입니다.</span
            >
          </div>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import {
  postSecureLogin,
  postWithdrawUser,
  postRecoveryUser,
} from 'Api/modules'
import { mapGetters } from 'vuex'
export default {
  props: {
    isSns: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userPw: '',
      isNaver: false,
      isKakao: false,
    }
  },
  computed: {
    ...mapGetters(['account']),
    isKakaoHidden() {
      if (this.isKakao === true || this.isNaver === true) {
        return 'password'
      } else {
        return 'text'
      }
    },
  },
  methods: {
    async login() {
      if (!this.userPw) {
        this.$toasted.error('비밀번호를 입력하세요.')
        return false
      }

      const saveData = {
        userId: this.account.userId,
        userPw: this.userPw,
      }

      //입력 오류
      // if (!this.validateInput(saveData)) return false

      try {
        await postSecureLogin(saveData)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async retire() {
      if (!this.userPw) {
        this.$toasted.error('비밀번호를 입력하세요.')
        return false
      }

      const saveData = {
        userId: this.account.userId,
        userPw: this.userPw,
      }

      //입력 오류
      // if (!this.validateInput(saveData)) return false

      try {
        await postWithdrawUser(saveData)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async rollbackRelease() {
      const saveData = {
        userId: this.account.userId,
      }

      try {
        await postRecoveryUser(saveData)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
  },
  mounted() {
    this.isKakao = this.account.joinType === 'KAKAO' ? true : false
    this.isNaver = this.account.joinType === 'NAVER' ? true : false
    //this.account.userId =
    //  this.account.joinType == 'KAKAO' ? '카카오' : this.account.userId
    //this.account.userId =
    //  this.account.joinType == 'NAVER' ? '네이버' : this.account.userId
    if (this.isSns == true) {
      window.$('input[type=password]').attr('readonly', true)
      this.userPw = this.account.userId
    }
  },
}
</script>

<style lang="scss">
.sub-cont .myp-form-wrap .form-item-wrap .SNS-text {
  width: 100%;
  display: block;
  text-align: center;
  color: red;
  font-weight: 500;
}
</style>
